
module.exports =
  metaInfo:
    title: 'Экономия на продуктах питания: вкусные советы'
    meta: [
      { name: 'description', content: 'Как экономить на еде с пользой для здоровья и кошелька. Эффективная экономия на продуктах и приготовлении пищи в советах от ХаниМани.' }
      { name: 'keywords', content: 'как экономить на продуктах, экономить на питании, экономить на еде, экономия на продуктах' }
      { name: 'human_title', content: 'Экономия на продуктах питания: вкусные советы' }
      { name: 'human_subtitle', content: 'Денег иногда может не хватать, а кушать хочется всегда' }
    ]
